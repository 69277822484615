import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

import { init } from "ityped";

export default class ScrollFunc {
    constructor() {
        this.init();
    }

    init() {
        this.setScrollTrigger();
    }

    setScrollTrigger() {
        const revealElm = [...document.querySelectorAll(".js-scrollreveal")];

        revealElm.forEach((el) => {
            ScrollTrigger.create({
                trigger: el,
                start: "top 85%",
                //markers: true,
                onEnter: () => {
                    el.setAttribute("data-reveal", "1");

                    if (el.getAttribute("data-typed") === "1" && el.getAttribute("data-typeend") !== "1") {
                        this.typeCatch(el);
                        el.setAttribute("data-typeend", "1");
                    }

                    if (el.getAttribute("data-splittxt") === "1") {
                        const mode = el.getAttribute("data-sectionttl") === "1" ? "none" : "random";
                        this.splitIn(el, mode);
                    }
                },
            });
        });

        const sectionElm = [...document.querySelectorAll(".js-section")];
        let idObj = { id: "mv" };
        const texChangeEv = new CustomEvent("texChange", { detail: idObj });

        sectionElm.forEach((el) => {
            ScrollTrigger.create({
                trigger: el,
                start: "top 100%",
                end: "bottom 100%",
                //markers: true,
                onEnter: () => {
                    const id = el.getAttribute("data-id");
                    document.body.setAttribute("data-currentsection", id);
                    idObj.id = id;
                    window.dispatchEvent(texChangeEv);
                },
                onEnterBack: () => {
                    const id = el.getAttribute("data-id");
                    document.body.setAttribute("data-currentsection", id);
                    idObj.id = id;
                    window.dispatchEvent(texChangeEv);
                },
            });
        });
    }

    splitIn(elm, mode) {
        const txts = [...elm.querySelectorAll(".c-splittxt")];

        if (mode === "random") {
            txts.forEach((txt, index) => {
                setTimeout(() => {
                    txt.setAttribute("data-active", "1");
                }, Math.random() * 750);
            });
        } else {
            txts.forEach((txt, index) => {
                setTimeout(() => {
                    txt.setAttribute("data-active", "1");
                }, index * 100);
            });
        }
    }

    typeCatch(tgt) {
        let txt = tgt.getAttribute("data-typetxt");
        txt = txt.replaceAll("<br/>", "\n");
        init(tgt, { showCursor: false, strings: [txt], loop: false, disableBackTyping: false, typeSpeed: 70 });
    }
}
