const modal = () => {
    const openbuttons = [...document.querySelectorAll(".js-modal_open")];
    const closebuttons = [...document.querySelectorAll(".js-modal_close")];
    const detailClosebuttons = [...document.querySelectorAll(".js-detail_close")];
    const modals = [...document.querySelectorAll(".js-modal")];
    const specialSingle = document.querySelector(".special__single__body");
    const newsSingle = document.querySelector(".newsmodal");

    const detailOpen = (el) => {
        const wrapper = document.querySelector(".js-detailimg");
        let src = el.getAttribute("src");
        const detailImg = document.createElement("img");

        //originalイメージに変更
        src = src.replace(/-[0-9].*\./, ".");

        detailImg.src = src;

        wrapper.appendChild(detailImg);
    };

    const detailClose = (el) => {
        const wrapper = document.querySelector(".js-detailimg");
        wrapper.innerHTML = "";
    };

    const openFunc = (e, _tgtmodal) => {
        e.preventDefault();
        let tgtmodal = null;

        if (!_tgtmodal) {
            tgtmodal = document.querySelector(`.js-modal[data-modalid=${e.currentTarget.getAttribute("data-modalid")}]`);
        } else {
            tgtmodal = document.querySelector(`.js-modal[data-modalid=${_tgtmodal}]`);
        }

        tgtmodal && tgtmodal.setAttribute("data-active", "1");
        document.body.classList.add("-lock");
    };

    openbuttons.forEach((el) => {
        el.addEventListener("click", (e) => {
            openFunc(e);
        });
    });

    if (specialSingle) {
        specialSingle.addEventListener("click", (e) => {
            if (e.target.getAttribute("src") && e.target.getAttribute("src").indexOf("uploads") !== -1) {
                //WPからのアップロード画像時のみ
                openFunc(e, "imgdetail");
                detailOpen(e.target);
            }
        });
    }

    if (newsSingle) {
        newsSingle.addEventListener("click", (e) => {
            if (e.target.getAttribute("src") && e.target.getAttribute("src").indexOf("uploads") !== -1) {
                //WPからのアップロード画像時のみ
                openFunc(e, "imgdetail");
                detailOpen(e.target);
            }
        });
    }

    closebuttons.forEach((el) => {
        el.addEventListener("click", () => {
            modals.forEach((el) => el.setAttribute("data-active", "0"));
            document.body.classList.remove("-lock");
        });
    });

    detailClosebuttons.forEach((el) => {
        el.addEventListener("click", () => {
            detailClose();
        });
    });
};

export { modal };
