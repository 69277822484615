//functions
import { modal } from "./functions/modal.es6";

//classess
import Movie from "./classes/movie.es6";
import ScrollFunc from "./classes/scrollfunc.es6";

export default class Main {
    constructor() {
        this.movie = new Movie();
        this.scrollFunc = null;
        this.init();
    }

    init() {
        this.setFuncs();
        this.setSplit();
        this.setImgProtect();
        this.scrollFunc = new ScrollFunc();
    }

    setSplit() {
        const splittxt = [...document.querySelectorAll(".js-splittxt")];

        splittxt.forEach((txt) => {
            const textContent = txt.textContent;
            const className = txt.parentNode.getAttribute("data-sectionttl") === "1" ? "c-splittxt c-splitttl" : "c-splittxt";
            const newTextContent = [...textContent].map((char) => `<span class="${className}">${char}</span>`).join("");
            txt.innerHTML = newTextContent;
        });
    }

    setFuncs() {
        modal();
    }

    // 画像の保存禁止
    setImgProtect(){
        
        document.querySelectorAll('.c-protect').forEach(protectElm=>{
            protectElm.addEventListener('contextmenu', e=>{
                e.preventDefault();
            });
            protectElm.addEventListener('selectstart', e=>{
                e.preventDefault();
            });
            protectElm.addEventListener('mousedown', e=>{
                e.preventDefault();
            });
        });
    }
}
